<template>
    <page-head-wrapper>
        <div class="wrap">
            <a-icon class="icon" type="exclamation-circle" /> 
            <span class="info">待开发的页面</span>
        </div>
    </page-head-wrapper>
</template>

<script>
import { getRandomIntInclusive as r } from "@/utils/tools"
export default {
    data() {
        return {
            color: `rgb(${r(0, 255)},${r(0, 255)},${r(0, 255)})`,
        }
    },
    computed: {
        colorObj() {
            return {
                color: this.color
            }
        }
    },
    // watch: {
    //     $route: {
    //         handler: function() {
    //             this.color = `rgb(${r(0, 255)},${r(0, 255)},${r(0, 255)})`
    //         },
    //         immediate: true
    //     }
    // }
}
</script>

<style lang="less" scoped>
.wrap {
    height: calc(~"100vh - 220px");
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    .icon {font-size: 48px; color: #999;}
    .info {font-size: 14px; margin-top: 24px; color: #666;}
}
</style>